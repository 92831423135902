import React from 'react'
import Select from 'react-select'

export default function SearchableDropdown({ className ,selectedOption, noOptionsAvailable,onChange, options, name ,placeholder,isDisabled, isMulti = false,}) {


    const allOptions = options.map((option) => {
        return { value: option, label: option }
    })

    // const selectedOptionForDropdown = selectedOption ? { value: selectedOption, label: selectedOption } : null
    const selectedOptionForDropdown = isMulti
    ? selectedOption?.map((opt) => ({ value: opt, label: opt })) // Convert array of selected options for multi-select
    : selectedOption
    ? { value: selectedOption, label: selectedOption } // Single select
    : null;

    return (
        <>
            <div className= {`d-flex flex-column`} >
                <p style={{ marginBottom: '3px', marginTop: '15px' }}>{name}</p>
                <Select
                    className = {className}
                    style={{height: '20px'}}
                    options={allOptions}
                    value={selectedOptionForDropdown}
                    placeholder={placeholder}
                    onChange={onChange}
                    noOptionsMessage={() => noOptionsAvailable}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                />
            </div>
        </>
    )
}


